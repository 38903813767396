.winners-circle-title {
    @apply flex justify-center text-cxa_primary;
    span {
        @apply bg-white rounded-full w-12 h-12 lg:w-16 p-1.5 lg:p-2 self-end -translate-y-full absolute right-0 sm:static;
        // &::after {
        //     @apply block content-[''] w-full h-full bg-ai-gray-900 rounded-full;
        // }
    }
}
.stats {
    @apply text-base text-center sm:text-left;
    span {
       @apply bg-clip-text text-transparent font-bold text-6xl after:content-["\a"] after:whitespace-pre; 
    }
}